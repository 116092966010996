<nav class="lc-navigation-bar mb-3" mat-tab-nav-bar [tabPanel]="tabPanel">
  <!-- Display each tab item as a link-->
  <ng-container *ngFor="let tab of tabs">
    <a *ngIf="tab.isVisible$ | async" mat-tab-link [active]="tab.id == activeId" [id]="tab.id" [routerLink]="tab.path">{{tab.label}}</a>
  </ng-container>
</nav>

<!-- The router outlet displays the child component -->
<mat-tab-nav-panel #tabPanel>
  <router-outlet />
</mat-tab-nav-panel>
