<ng-container *ngIf="data$ | async as data">
  <table id="qr-statistics-table" mat-table [dataSource]="data?.qrCodes">
    <ng-container matColumnDef="productTitle">
      <th *matHeaderCellDef id="field-product-name" mat-header-cell>Product Name</th>
      <td *matCellDef="let qrCode" mat-cell>{{ qrCode?.data?.productTitle }}</td>
      <td *matFooterCellDef mat-footer-cell>Total</td>
    </ng-container>
    
    <ng-container matColumnDef="numberOfScans">
      <th *matHeaderCellDef id="field-number-of-scans" mat-header-cell>Number of Scans</th>
      <td *matCellDef="let qrCode" mat-cell>{{ qrCode?.hits }}</td>
      <td *matFooterCellDef mat-footer-cell>{{ data?.totalHits }}</td>
    </ng-container>
    
    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    <tr *matFooterRowDef="displayedColumns" mat-footer-row></tr>
  </table>
</ng-container>
