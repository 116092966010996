import { Deserializable } from './deserializable.model';
import {
  PropertyStatus, PropertyType, PropertyBedroomsLabel, PropertyBathroomsLabel, PropertyPriceLabel,
} from './property.type';
import { Address } from './address.model';
import { LotSizeUnitOfMeasure } from './lot-size-unit-of-measure.enum';

export class ListingUi implements Deserializable<ListingUi> {
  _id: string;
  mlsId: string;
  coldwellBankerHomesUrl: string;
  coldwellBankerHomesUrlAudit: string;
  propertyType: string = PropertyType.SINGLE_FAMILY;
  status:string = PropertyStatus.ACTIVE;
  listPrice:number;
  displayAsPriceOnRequest:Boolean;
  bedrooms: number;
  bedroomsLabel: string = PropertyBedroomsLabel.BEDS;
  bathrooms:number;
  bathroomsLabel: string = PropertyBathroomsLabel.BATHS;
  sqFt:number;
  lotSize:number;
  lotSizeUnitOfMeasure:LotSizeUnitOfMeasure;
  globalLuxuryListing = false;
  address: Address;
  priceLabel: string = PropertyPriceLabel.OFFERED_AT;
  features: string[];

  /**
   * @param listing Listing to copy (optional)
   */
  constructor(listing?: Partial<ListingUi>) {
    if (listing) {
      this.deserialize(listing);
    }
  }

  deserialize(input: any): ListingUi {
    Object.assign(this, input);
    return this;
  }

  public static createChangeLogListingUi(): ListingUi {
    const obj: ListingUi = {
      propertyType: 'Single family',
      status: 'Active',
      bedroomsLabel: 'Bed(s)',
      bathroomsLabel: 'Bath(s)',
      globalLuxuryListing: false,
      priceLabel: 'Offered at',
      address: null,
      mlsId: '1234',
      listPrice: 5500001,
      displayAsPriceOnRequest: false,
      coldwellBankerHomesUrl: null,
      coldwellBankerHomesUrlAudit: null,
      bedrooms: null,
      bathrooms: 2,
      sqFt: null,
      lotSize: 12,
      lotSizeUnitOfMeasure: null,
      _id: '621e84eb4c88af3e94764e74',
      features: null,
      deserialize: null,
    };
    return new ListingUi(obj);
  }
}
