<ng-template #photoUpload>
  <label *ngIf="label" class="form-label" [for]="inputId || 'uploadPhotos'">{{label}}</label>
  <ngx-file-drop customstyle="mb-4 lc-drop-zone" id="lc-file-upload" (onFileDrop)="handleDroppedFiles($event)">
    <ng-template ngx-file-drop-content-tmp>
    <div *ngIf="!processing" class="dz-margin align-center">
      <div>
        <div>
          <span class="text-faded">{{instructions}}</span>
        </div>
        <div class="mt-2">
          <img alt="upload images" height="63px" [class.round]="photoStyle === 'round'" [src]="imageUrl || '/assets/images/upload.svg'">
        </div>
        <div class="mt-2">
          <input #fileInput class="d-none lc-file-upload-input" type="file" [accept]="allowExt" [id]="inputId || 'uploadPhotos'" [multiple]="multipleUpload" (change)="handleSelectedFiles($event)" (click)="fileInput.value = null" />
          <button class="lc-file-upload-btn" mat-raised-button type="button" [id]="btnId || 'chooseFileButton'" (click)="chooseFiles();">Upload</button>
        </div>
    </div>
    </div>
    <div *ngIf="processing">
      <div class="col">
        <p class="text-faded">Please wait while uploading the file(s)...</p>
      </div>
    </div>
    </ng-template>
  </ngx-file-drop>
</ng-template>
<ng-container *ngTemplateOutlet="uploadTemplate ? uploadTemplate: photoUpload" />
