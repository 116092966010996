<div >
  <ng-container *ngIf="viewModel$ | async as viewModel">
    <button id="notifications-icon-button" mat-icon-button [matMenuTriggerFor]="notificationMenu" (click)="getAllNotes(viewModel)" >
      <mat-icon class="material-icons-outlined" matBadgeColor="primary" matBadgePosition="below after" matBadgeSize="small" [matBadge]="viewModel.badge" [matBadgeHidden]="!viewModel.count">{{viewModel.count ? 'notifications_active' : 'notifications'}}</mat-icon>
    </button>

      <!-- Menu dropdown for the Notification menu -->
    <mat-menu #notificationMenu="matMenu" [class]="'notifications-menu-panel'">
      <div id="notifications-menu">
        <h3 class="mat-title m-0 px-3 py-2 border-bottom text-default">Notifications</h3>

        <!-- Clear All button -->
        <button *ngIf="viewModel.count" class="my-0 border-bottom" id="clear-all-button" mat-menu-item  (click)="viewModel.markAllNotificationsAsRead()">
          <div class="fx-row x-end y-center fx-gap-sm">
            <mat-icon class="material-icons-outlined" style="margin-right: 0 !important;">done_all</mat-icon>
            <span>Clear all</span>
          </div>
        </button>

        <!-- Display all notifications-->
        <ng-container *ngIf="notesViewModels && notesViewModels.length > 0">
          <cdk-virtual-scroll-viewport class="scoll-view-port" itemSize="20" maxBufferPx="250" minBufferPx="250" (scrolledIndexChange)="onNotesScroll()">
            <ng-container *cdkVirtualFor="let notificationViewModel of notesViewModels; let i = index">
              <button class="border-bottom py-2 my-0" mat-menu-item (click)="onNotificationClicked(notificationViewModel, viewModel, $event)">
                <div class="fx-row y-center fx-gap-lg">
                  <mat-icon class="material-icons-outlined">{{notificationViewModel.icon}}</mat-icon>
                  <div class="fx-column">
                    <span class="mat-body-2">{{notificationViewModel.title}}</span>
                    <div class="mat-body-1 mat-caption wrap-text" [innerHTML]="notificationViewModel.text"></div>
                    <span class="mat-caption mat-hint">{{notificationViewModel.when}}</span>
                  </div>
                </div>
              </button>
            </ng-container>
          </cdk-virtual-scroll-viewport>

        </ng-container>
      </div>

    </mat-menu>

  </ng-container>
</div>
