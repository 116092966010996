import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Data, Router, CanActivate, CanActivateChild } from '@angular/router';
import { LaunchDarklyService, UserService } from '../services';

export interface IFeatureGuardData {
  name: string;
  version: 'beta' | 'classic';

  flagId?: string;
  flagOnValue?: boolean;

  preferenceId?: string;
  preferenceOnValues?: any[];

  onUrl: string;
  offUrl: string;
}

@Injectable()
export class FeatureGuard implements CanActivate, CanActivateChild {
  private readonly router = inject(Router);
  private readonly authService = inject(UserService);
  private readonly flagService = inject(LaunchDarklyService);

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return await this.performCheck(route.data['featureGuardData'], state);
  }

  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return await this.performCheck(route.data['featureGuardData'], state);
  }

  private async performCheck(routeData: Data, state: RouterStateSnapshot): Promise<boolean> {
    const feature = routeData as IFeatureGuardData;

    // Step 1: Check Feature Flag
    if (feature.flagId) {
      const requiredValue = feature.flagOnValue ?? true;
      const isEnabled = await this.flagService.isFeatureEnabled(feature.flagId as any);

      if (isEnabled !== requiredValue) {
        if (feature.version === 'classic') { return true; } // We are already on classic view.

        // Redirect to redirect url. return false
        console.log(`
        [FeatureGuard]: Flag is not enabled in launch darkly. Cannot navigate to the ${feature.name} feature!
        `);
        return this.preventNavigation(state.url, feature.offUrl);
      }
    }

    // Step 2: Check UserPreferences
    if (feature.preferenceId) {
      const requiredValues = feature.preferenceOnValues ?? [undefined];
      const userPreference = this.authService.getPreferenceValue(feature.preferenceId);
      const isFeatureEnabled = requiredValues.includes(userPreference);

      if (isFeatureEnabled) {
        if (feature.version === 'beta') { return true; } // We are already on the beta path. Return true

        // Feature is enabled. Return to new feature!
        console.log(`[FeatureGuard]: Feature is enabled by user. Redirecting to the ${feature.name} feature!`);
        return this.preventNavigation(state.url, feature.onUrl);
      }

      if (feature.version === 'classic') { return true; } // We are already on the 'classic' path. Return true

      // Feature is enabled. Return to new feature!
      console.log(`
        [FeatureGuard]: ${feature.name} feature is turned on, but user has it turned off.
        Redirecting to the ${feature.version} view!
      `);
      return this.preventNavigation(state.url, feature.offUrl);
    }

    return true;
  }

  private preventNavigation(requestedUrl: string, redirectUrl: string) {
    if (redirectUrl) {
      // If it matches
      const newUrl = this.getRelativeUrl(requestedUrl, redirectUrl);
      console.log(`[FeatureGuard]: Redirecting from ${requestedUrl} to ${newUrl}`);
      this.router.navigate([newUrl]);
    }

    return false;
  }

  private getRelativeUrl(requestedUrl: string, redirectUrl: string) {
    const redirectParts = redirectUrl.split('/');
    const fixedParts = redirectParts.filter((part) => !part.startsWith('.'));

    // Always pop the last one as this will never be a part of the requested url
    const levelsUp = 1 + redirectParts.filter((part) => part === '..').length;
    const newUrlPaths = (requestedUrl || '').split('/').slice(0, -levelsUp);

    newUrlPaths.push(...fixedParts);
    const newUrl = newUrlPaths.join('/');
    return newUrl;
  }
}
