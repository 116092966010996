import {
  Directive, HostBinding, Input, OnChanges, SimpleChanges,
} from '@angular/core';

/**
 * What is this directive for?: This directive is used to create and style an avatar
 * to maintain consistency, make developing more efficient and remove duplicated code across the system.
 *
 * Why a Directive?: The reason a directive is used instead of a component
 * is due to the fact that it does not require any additional business logic nor
 * complex HTML.
 *
 * How to use?: Simply bind to the lcAvatar property in an image - i.e.
 * <img [lcAvatar]="'assets/this_is_my_photo' [isVip]="false" [size]="50">
 */
@Directive({
  selector: 'img[lcAvatar]',
  standalone: false,
})
export class AvatarDirective implements OnChanges {
  /** Binds to the src of the host element */
  @HostBinding('src') src?: string;

  /** Adds the class 'avatar' to the host element */
  @HostBinding('class.avatar') avatar: boolean = true;

  /** The size determines the height/width of this avatar */
  @Input()
  @HostBinding('style.height.px')
  @HostBinding('style.width.px')
    size: number = 40;

  /** Specifies whether to apply borders to this avatar */
  @Input()
  @HostBinding('class.borders')
    isVip?: boolean;

  /** The url of the photo to use for the avatar. Fallback to default image */
  @Input() lcAvatar: string = 'assets/images/user.png';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['lcAvatar']) {
      this.src = this.lcAvatar || 'assets/images/user.png';
    }
  }
}
