<div class="fx-row fx-gap-sm y-center">

  <!-- Icon -->
  <div *ngIf="iconTemplate || config?.icon || icon" class="header-icon fx-column y-center">

    <ng-container *ngIf="iconTemplate; else defaultIcon"
                  [ngTemplateOutlet]="iconTemplate" />
    <ng-template #defaultIcon>
      <mat-icon class="material-icons-outlined">{{(config?.icon || icon)}}</mat-icon>
    </ng-template>

  </div>

  <!-- Header / Subheader -->
  <div class="text-container fx-column fx-1-1-0" [ngClass]="'overflow-' + overflow">

    <ng-container *ngIf="(config?.header || header) as text">
      <span class="header-text" [innerHTML]="text" [ngClass]="headerClass +' overflow-' + overflow">
      </span>
    </ng-container>

    <ng-container *ngIf="(config?.subheader || subheader) as text">
      <span class="subheader-text" [innerHTML]="text"
            [ngClass]="subheaderClass + ' overflow-' + overflow">
      </span>
    </ng-container>

  </div>

  <!-- Action -->
  <div *ngIf="suffixTemplate || config?.suffixIcon || suffixIcon" class="header-suffix fx-column y-center">
    <ng-container *ngIf="suffixTemplate; else defaultSuffix"
                  [ngTemplateOutlet]="suffixTemplate" />
    <ng-template #defaultSuffix>
      <mat-icon class="material-icons-outlined">{{(config?.suffixIcon || suffixIcon)}}</mat-icon>
    </ng-template>
  </div>


</div>