import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, CanLoad, CanActivateChild } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(private authService: AuthenticationService) { }

  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isAuthenticated = await this.authService.isAuthenticated$();
    if (isAuthenticated) {
      // Logged in so return true
      return true;
    }

    // Not logged in so redirect to login page
    const postLoginRedirectTo = state.url;
    localStorage.removeItem('post-login-redirect');
    if (postLoginRedirectTo) {
      localStorage.setItem('post-login-redirect', state.url);
    }

    this.authService.redirectToLogin();
    return false;
  }

  async canLoad(): Promise<boolean> {
    return await this.isAuthenticated$();
  }

  async canActivateChild(): Promise<boolean> {
    return await this.isAuthenticated$();
  }

  private async isAuthenticated$(): Promise<boolean> {
    return await this.authService.isAuthenticated$();
  }
}
