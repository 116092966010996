<lc-base-dialog id="image-cropper-dialog" [title]="title" (actionClicked)="onCancel()">

  <!--Modal Body-->
  <ng-template #content>
    <div class="fx-column fx-gap-sm">

      <!-- Photo Cropper Stepper -->
      <mat-horizontal-stepper #stepper [class.hide-header]="viewModels.length === 1" [linear]="true" (selectionChange)="onStepChanged($event)">
        <mat-step *ngFor="let viewModel of viewModels" [stepControl]="viewModel.hasViewed" >
          <ng-template matStepLabel>{{viewModel.request?.display}}</ng-template>

          <div class="fx-row x-center">
            <lc-image-cropper class="round restrict-height fx-1-1" [photo]="viewModel.url" [settings]="viewModel.settings" (cropperLoaded)="imageLoaded(viewModel, $event)" />
          </div>
        </mat-step>
      </mat-horizontal-stepper>

    </div>
  </ng-template>

  <ng-template #actions>
    <div class="fx-row x-end fx-gap-sm">
      <button class="mr-2" id="cropper-cancel" mat-stroked-button (click)="onCancel()">Cancel</button>
      <button color="primary" id="cropper-next" mat-raised-button [disabled]="!imageReadyToCrop" (click)="onNext()">{{ nextText$ | async }}</button>
    </div>
  </ng-template>
</lc-base-dialog>


