<mat-form-field appearance="outline"
                floatLabel="always"
                [class.mat-form-field-readonly]="readonly"
                [class.ng-valid]="errors === ''"
                [id]="idPrefix + '-form-field'"
>

  <!-- Label -->
  <mat-label [id]="idPrefix + '-label'">
    <template *ngIf="labelTemplate; else defaultLabel" [ngTemplateOutlet]="labelTemplate"></template>
    <ng-template #defaultLabel>
      {{label}}
    </ng-template>
  </mat-label>

  <!-- Input -->
  <input matInput thousandSeparator=","
    [allowNegativeNumbers]="inputMask?.allowNegative"
    [dropSpecialCharacters]="maskDropSpecialCharacters"
    [errorStateMatcher]="matcher"
    [formControl]="formControl"
    [id]="idPrefix + '-input'"
    [mask]="inputMask?.mask"  [patterns]="inputMask?.pattern" [placeholder]="placeholder"
    [readonly]="readonly" (blur)="onBlur()">

  <!-- Hint-->
   @if(hint || hintTemplate) {
    <mat-hint *ngIf="hint; else hintTemplate" class="w-100" [id]="idPrefix + '-hint'">
      {{hint}}
    </mat-hint>
  }

  <!-- Error -->
  <mat-error *ngIf="formControl && formControl.invalid" [id]="idPrefix + '-error'" [innerHTML]="errors" />

  <!-- Suffix -->
  <div *ngIf="suffix || suffixTemplate" matSuffix>
    <template *ngIf="suffixTemplate; else defaultSuffix" [ngTemplateOutlet]="suffixTemplate" ></template>
    <ng-template #defaultSuffix>
      <span *ngIf="suffix">{{suffix}}</span>
    </ng-template>
  </div>
</mat-form-field>

