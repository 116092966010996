<mat-form-field appearance="outline" class="hide-notched-outline" floatLabel="always" [hideRequiredMarker]="!label">

  <!-- Label -->
  <mat-label [id]="idPrefix + '-label'">{{label}}</mat-label>

  <mat-radio-group class="lc-radio-group" [ngClass]="layout === 'row' ? 'fx-row fx-gap-md' : 'fx-column fx-gap-xs'">
    <mat-radio-button *ngFor="let option of options" [checked]="option.isSelected" [value]="option.value" (change)="onSelect(option)">

      <!-- Label Section-->
      <div class="option-label fx-row y-center fx-gap-xs">
        <div class="overflow-ellipsis label fx-1-1-0">{{option.label}}</div>
        <mat-icon *ngIf="option.description" class="material-icons-outlined text-faded description-icon" matTooltipPosition="after" [matTooltip]="option.description">info</mat-icon>
      </div>

    </mat-radio-button>
    <!-- Hidden matInput plugs into formControl -->
    <input class="d-none" matInput [errorStateMatcher]="matcher" [formControl]="formControl">
  </mat-radio-group>
  <mat-error *ngIf="formControl && formControl.invalid" class="error-message" [innerHTML]="errors" />
</mat-form-field>
