<div style="position: relative" [id]="idPrefix + '-container'">
  <mat-form-field appearance="outline" floatLabel="always" [class.selected]="isSelected" [id]="idPrefix + '-input'">
    <mat-label [id]="idPrefix + '-label'" >{{label}}</mat-label>
    <mat-select *ngIf="options$ | async as selectOptions" disableOptionCentering="true" hideSingleSelectionIndicator="true" [attr.aria-owns]="getOptionAriaId()" [formControl]="formControl"
                [multiple]="multiple" [panelClass]="dropdownClass" [placeholder]="display" (openedChange)="onOpenedChange($event)"

                (selectionChange)="onSelectionChanged(selectOptions)">
      <mat-select-trigger>
        <ng-container *ngIf="!displayTemplate; else displayTemplate">
          {{ display }}
        </ng-container>
      </mat-select-trigger>

      <mat-option *ngIf="displayOptions?.display" class="header-option" disabled id="disabled-option">
        <!-- This is a hack to get the menu option list to display a header-->
        {{displayOptions.display}}
      </mat-option>

      <mat-option *ngFor="let option of selectOptions" [id]="getOptionId(option)" [value]="valuePath ? option[valuePath] : option">
        <span *ngIf="optionTemplate == null; else optTemplate" class="option-title">
          {{ displayPath ? option[displayPath] : option }}
        </span>
        <ng-template #optTemplate>
          <template [ngTemplateOutlet]="displayDateRangeTemplate || optionTemplate" [ngTemplateOutletContext]="{item: option}"></template>
        </ng-template>
      </mat-option>
    </mat-select>

    <mat-icon *ngIf="isSelected && deselectAllEnabled" class="clear-button" matSuffix (click)="clear($event)">
      close
    </mat-icon>

    <mat-hint *ngIf="hint" [id]="idPrefix + '-hint'">{{hint}}</mat-hint>
    <mat-error *ngIf="formControl && formControl.invalid" [id]="idPrefix + '-error'" [innerHTML]="errors" />
  </mat-form-field>
</div>
