import { Injectable } from '@angular/core';
import {
  filter, map, shareReplay, take, tap,
} from 'rxjs/operators';
import { Observable, firstValueFrom } from 'rxjs';
import { ActionsSubject, Store } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import {
  GetOrderComplete,
  SELECTEDORDER,
  UpdateCurrentOrderState,
  UpdateCurrentProductState,
  UpdateOrderComplete,
} from '../state-mgmt/order/order.actions';
import {
  Task,
  PackageInstance,
  PrintOrder,
  Media,
  MarketingOrderTransitions,
  UploadPhoto,
  ListingPhoto,
  Listing,
  OrderState,
  MarketingOrder,
  ProductDescription,
  ProductCode,
  ProductInstance,
  TemplateInstance,
} from '../models';
import { cloneOrder } from '../state-mgmt/order/order.reducer';

/**
 * @deprecated We are transitioning to signals for marketing order state management and consolidating endpoints
 * to improve data structure and maintainability. Use `MarketingOrderService` from `ngx-ui` instead to align
 * with these goals.
 */
@Injectable()
export class MarketingOrderService {
  resource = 'marketing-orders';

  v2Resource = 'orders';

  readonly currentOrder$: Observable<MarketingOrder | undefined>;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private store: Store<any>,
    private actions: ActionsSubject,
  ) {
    this.currentOrder$ = this.store.select(SELECTEDORDER).pipe(
      shareReplay(1),
    );
  }

  /**
   * Returns the current order in the store.
   * NOTE: This will clone the order so the original order remains unmodified
   */
  getCurrentOrder$() {
    return this.currentOrder$.pipe(
      filter((order) => !!order),
      map(cloneOrder),
      shareReplay(1),
    );
  }

  /**
   * Get all marketing orders owned by the currently logged in user. This will
   * also set the summary flag on the MarketingOrder object
   *
   * @return an Observable that results in an array of MarketingOrders
   */
  getAllMyOrders(page: number, filters: Partial<MarketingOrder>): Observable<{ orders: MarketingOrder[], complete: boolean }> {
    return this.apiService.get(`${this.v2Resource}/summary`, { page, filters }, { version: 'v2' }).pipe(
      map((response: { orders: MarketingOrder[], complete: boolean }) => {
        const orderObjs = [];
        response.orders.forEach((o: MarketingOrder) => {
          o.summary = true;
          orderObjs.push(new MarketingOrder(o));
        });
        return { orders: orderObjs, complete: response.complete };
      }),
    );
  }

  getOrder(id: string): Observable<MarketingOrder> {
    return this.apiService.get(`${this.v2Resource}/${id}`, undefined, { version: 'v2' }).pipe(map((body) => new MarketingOrder(body)));
  }

  /**
   * Queries for the marketingOrder photos. Includes the photographerId in the route parameters
   * if provided.
   */
  getOrderPhotos(marketingOrderId: string, photographerId?: string, productCode?: string): Observable<ListingPhoto[]> {
    const route = `${this.resource}/${marketingOrderId}/photos`;
    const params: any = {};
    if (photographerId) {
      params.photographerId = photographerId;
    }
    if (productCode) {
      params.productCode = productCode;
    }
    return this.apiService.get(route, params).pipe(
      map((photos) => photos.map((photo) => new ListingPhoto(photo))),
    );
  }

  /**
   * Queries lcms-printer for the status of all print jobs for this marketingOrder
   */
  getPrintOrders(marketingOrderId: string): Observable<PrintOrder[]> {
    const route = `${this.resource}/${marketingOrderId}`;
    const params: any = {};
    return this.apiService.get(route, params).pipe(
      map((printOrders) => printOrders.map((printOrder) => new PrintOrder(printOrder))),
    );
  }

  /**
   * Updates values on the Order Listing object with dirty values of a ListingForm object.
   * Returns both the updated Listing and updated Tasks objects which will be sent to the
   * Store and update the local MarketingOrder object.
   * @returns
   * @param orderId
   * @param updates
   */
  async updateOrderListing(
    orderId: string,
    updates: Partial<Listing>,
  ): Promise<{ listing: Listing, tasks: Task[], isVIPOrder: boolean }> {
    const url = `orders/${orderId}/listing`;
    const response = await this.apiService.patch<{ listing: Listing, tasks: Task[], isVIPOrder: boolean }>(url, updates, { version: 'v2' });
    await this.updateCachedOrder(orderId, response, true);
    return response;
  }

  updateOrderPartial$(order: MarketingOrder, fields: string[]) {
    const clonedOrder = this.cleanOrder(order);

    fields = fields || [];
    const partial = {};
    // order.orderState = order.listing.orderState;
    fields.forEach((field) => {
      partial[field] = clonedOrder[field];
    });
    return this.apiService.put(`${this.resource}/${order._id}`, partial);
  }

  // Removes "read-only" properties that cannot be included in put or post operations
  private cleanOrder(order: MarketingOrder): MarketingOrder {
    // HACK: This is a temporary solution and should be removed.
    // We should be handling photos in a separate service instead of the model
    const clonedOrder: Partial<MarketingOrder> = { ...order };
    if (order.listing) {
      const clonedListing = { ...order.listing } as Listing;
      clonedOrder.listing = clonedListing;
    } else {
      delete clonedOrder.listing;
    }
    delete clonedOrder.summary;
    delete clonedOrder.audit;
    delete clonedOrder.statusHistory;
    delete clonedOrder.pricing; // Do not allow the UI to update the pricing
    delete clonedOrder.tasks; // Do not allow the UI to update the tasks
    delete clonedOrder.ai;

    this.cleanPackage(clonedOrder?.selectedPackage);
    if (clonedOrder.availablePackages) {
      clonedOrder.availablePackages.forEach((availablePackage) => this.cleanPackage(availablePackage));
    }
    return clonedOrder as MarketingOrder;
  }

  private cleanPackage(pkg: PackageInstance) {
    pkg?.products?.forEach((product) => {
      delete product.statusHistory;
      delete product.publishConsents;
    });
  }
  saveOrder(order: MarketingOrder) {
    const clonedOrder = this.cleanOrder(order);

    if (order._id) {
      return this.updateOrder$(clonedOrder);
    }
    return this.createOrder(clonedOrder);
  }

  saveOrderProductDescription(description: ProductDescription) {
    let url = `${this.resource}/${description.orderId}/marketingCopy`;

    if (description.productCode) {
      url += `?productCode=${description.productCode}`;
    }

    return this.apiService.put(url, {
      marketingCopyHeadline: description.marketingCopyHeadline,
      marketingCopyBody: description.marketingCopyBody,
    });
  }

  protected createOrder(order: MarketingOrder) {
    return this.apiService.post(this.v2Resource, this.cleanOrder(order), { version: 'v2' });
  }

  protected updateOrder$(order: MarketingOrder) {
    return this.apiService.put(`${this.v2Resource}/${order._id}`, this.cleanOrder(order), { version: 'v2' })
      .pipe(map((response) => new MarketingOrder(response)));
  }

  async loadOrder(id: string) {
    // Retrieve the new order
    const order = await this.getOrder(id).pipe(take(1)).toPromise();
    this.store.dispatch(GetOrderComplete({ payload: order }));
    return order;
  }

  /**
   * Helper function that will retrieve the fresh order and update the current order state
   * @param id The marketing order to fetch
   * @returns The refreshed marketing order
   */
  async refreshOrder(id: string) {
    // Retrieve the new order
    const updatedOrder = await this.getOrder(id).pipe(take(1)).toPromise();

    this.store.dispatch(UpdateCurrentOrderState({ payload: updatedOrder }));
    await this.actions.pipe(
      ofType(UpdateOrderComplete),
      filter((action) => action.payload?._id === id),
      take(1),
    ).toPromise();

    await this.updateCachedOrder(id, updatedOrder);
    return updatedOrder;
  }

  /**
   * Helper function that will retrieve the fresh product instance and
   * update the product in the current order state
   * @param orderId The marketing order to refresh
   * @param productCode The productCode to update
   * @returns The refreshed product instance
   */
  async refreshProduct(orderId: string, productCode: ProductCode) {
    // Retrieve the fresh product from the database
    const url = `orders/${orderId}/products/${productCode}`;
    const product = await firstValueFrom(
      this.apiService.find$(url, { version: 'v2', model: ProductInstance }),
    );

    this.store.dispatch(UpdateCurrentProductState({ orderId, product }));
    return product;
  }

  /**
   *  Update the state machine with the new order, and wait until the update order has been completed
   * @param orderId The orderId to update in the cache
   * @param order The order updates
   * @param patch Whether we should update as a patch or not
   * @returns The updated order
   */
  async updateCachedOrder(orderId: string, order: Partial<MarketingOrder>, patch: boolean = false) {
    this.store.dispatch(UpdateCurrentOrderState({ payload: { _id: orderId, ...order } as MarketingOrder, patch }));
    return await this.actions.pipe(
      ofType(UpdateOrderComplete),
      filter((action) => action.payload?._id === orderId),
      map((action) => action.payload),
      take(1),
    ).toPromise();
  }

  updateOrder(order: MarketingOrder) {
    return this.apiService.put(`${this.v2Resource}/${order._id}`, this.cleanOrder(order), { version: 'v2' }).pipe(
      map((response) => new MarketingOrder(response)),
      tap((updatedOrder) => this.store.dispatch(UpdateCurrentOrderState({ payload: updatedOrder }))),
    ).toPromise();
  }

  /** Updates the specific marketingOrder fields and then updates the store with the current marketingOrder */
  updateOrderPartial(order: MarketingOrder, fields: string[]) {
    return this.updateOrderPartial$(order, fields).pipe(
      map((response) => new MarketingOrder(response)),
      tap((updatedOrder) => this.store.dispatch(UpdateCurrentOrderState({ payload: updatedOrder }))),
    ).toPromise();
  }

  deleteOrder(order: MarketingOrder) {
    return this.apiService.delete(`${this.resource}/${order._id}`);
  }

  async submitOrder(orderId: string, payment: { stripeTokenId?: string, acceptedTerms: boolean }) {
    const route = `${this.v2Resource}/${orderId}/submit`;
    const order = await this.apiService.update(route, payment, { version: 'v2', model: MarketingOrder });
    this.store.dispatch(UpdateCurrentOrderState({ payload: order }));
    return order;

    // TODO: Handle errors and toasters
  }

  async performAction(marketingOrder: MarketingOrder, transition: MarketingOrderTransitions, reason?: string): Promise<MarketingOrder> {
    return await this.apiService.post<MarketingOrder>(`${this.resource}/${marketingOrder._id}/transition/${transition}`, { reason }).pipe(
      tap((updatedOrder) => this.store.dispatch(UpdateCurrentOrderState({ payload: updatedOrder }))),
    ).toPromise();
  }

  /**
   * Sends an update of the MarketingOrder for photos only.
   *
   * @param marketingOrder
   * @param photos
   * @param productCode
   */
  async setPhotos(marketingOrder: MarketingOrder, photos: any, productCode?: string): Promise<MarketingOrder> {
    let url = `marketing-orders/${marketingOrder._id}/photos`;

    if (productCode) {
      url += `?productCode=${productCode}`;
    }

    const updatedOrder = await this.apiService.put<MarketingOrder>(url, photos).pipe(
      map((order) => new MarketingOrder(order)),
      take(1),
    ).toPromise();

    // Let other listeners know this order has changed.
    // NOTE: may not be needed down the road
    this.store.dispatch(UpdateCurrentOrderState({ payload: updatedOrder }));
    return updatedOrder;
  }

  /**
   * Gets update of the MarketingOrder for impediments.
   *
   * @param marketingOrderId
   */
  getImpediments(marketingOrderId: string): Observable<MarketingOrder> {
    const url = `marketing-orders/${marketingOrderId}/impediments`;
    return this.apiService.get(url).pipe(
      tap((impediments) => impediments),
    );
  }

  /**
   * Adds a photo to a MarketingOrder.
   *
   * @param marketingOrder The MarketingOrder
   * @return Observable<any> of the response
   * @param listingPhotos
   * @param productCode is optional. When sent we are modifying the product instance photos
   */
  async addPhoto(marketingOrder: MarketingOrder, listingPhotos: ListingPhoto[], productCode: string): Promise<MarketingOrder> {
    if (!marketingOrder?._id) {
      console.log(`Cannot upload photos due to marketing order ID is ${marketingOrder._id}`);
      throw Error('EMPTYID');
    }

    let url = `marketing-orders/${marketingOrder._id}/photos?push=true`;
    if (productCode) {
      url += `&productCode=${productCode}`;
    }

    // Update the photos on the order
    const updatedOrder = await this.apiService.put<MarketingOrder>(url, listingPhotos).pipe(
      map((order) => new MarketingOrder(order)),
      take(1),
    ).toPromise();

    // Let other listeners know this order has changed.
    // NOTE: may not be needed down the road
    this.store.dispatch(UpdateCurrentOrderState({ payload: updatedOrder }));
    return updatedOrder;
  }

  /**
   * Update a single photo associated with a MarketingOrder or a product instance
   *
   * @param orderId
   * @param photoId
   * @param values the photo array
   */
  async updatePhoto(orderId: string, photoId: string, values: any): Promise<MarketingOrder> {
    const updatedOrder = await this.apiService.put(`marketing-orders/${orderId}/photos/${photoId}`, values).pipe(
      take(1),
    ).toPromise();

    // Let other listeners know this order has changed.
    // NOTE: may not be needed down the road
    this.store.dispatch(UpdateCurrentOrderState({ payload: updatedOrder }));
    return updatedOrder;
  }

  /**
   * Parses upload response into photo, adds to MarketingOrder, and updates the MarketingOrder on server
   */
  async addPhotoFromUploadAndUpdatePhotos(marketingOrder: MarketingOrder, photos: UploadPhoto[], photographerId?: string, productCode?: string): Promise<MarketingOrder> {
    const userId = this.userService.getUserId();
    const photoCount = marketingOrder.getPhotos().length; // TODO: This will not handle concurrency very well
    const listingPhotos: ListingPhoto[] = ListingPhoto.createFromRawPhotos(photos, userId, photoCount, photographerId);

    return await this.addPhoto(marketingOrder, listingPhotos, productCode);
  }

  /**
   * Update the orderstate. This is currently implemented on the marketing order collection
   *
   * @param id
   * @param state
   */
  updateOrderState(id: string, state: OrderState): Observable<any> {
    return this.apiService.put(`${this.v2Resource}/${id}/orderstate`, state, { version: 'v2' });
  }

  /**
   * Update media on a product in an order
   * @param id the marketing order id
   * @param productCode the product code to update
   * @param media the media data
   */
  updateMedia(id: string, productCode: string, media: Media) {
    return this.apiService.put(`${this.resource}/${id}/media?productCode=${productCode}`, media);
  }

  /**
   * Publishes the website
   * @param id Id of the marketing order t publish the website for
   * @param consent Consent is required
   */
  publishWebsite(id: string, consent: boolean) {
    return this.apiService.post(`${this.resource}/${id}/website?consent=${consent}`, {});
  }

  unPublishWebsite(id: string) {
    return this.apiService.post(`${this.resource}/${id}/unpublish`, {});
  }

  /**
   * Returns an object containing both "Opt In" and "Opt Out" prices of available packages within a marketing order.
   *
   * @param marketingOrderId
   */
  getPackagePricing$(marketingOrderId: string): Observable<{ [packageCode: string]: number }> {
    return this.apiService.get(`${this.v2Resource}/${marketingOrderId}/package-prices`, undefined, { version: 'v2' });
  }

  /**
   * Save selected template
   * @param order the order act on
   * @param productCode the product code
   * @param template the selected template
   */
  async selectTemplate(order: MarketingOrder, productCode: string, template: TemplateInstance): Promise<MarketingOrder> {
    const url = `marketing-orders/${order._id}/products/${productCode}/selectTemplate`;
    const updatedOrder = await this.apiService.update(url, { templateCode: template.code } as any, { model: MarketingOrder });
    return await this.updateCachedOrder(order._id, updatedOrder);
  }

  /**
   * Assign many orders to a single coordinator
   * @param orderIds an array of order ids to assign to the coordinator
   * @param coordinatorId the user id of the coordinator to assign to
   */
  bulkAssignOrders(orderIds: string[], coordinatorId: string) {
    const body = {
      orders: orderIds,
      coordinator: coordinatorId,
    };
    return this.apiService.put(`${this.resource}/bulk-assign`, body);
  }

  addPackagePricing(order: MarketingOrder) {
    return this.getPackagePricing$(order._id).pipe(
      map((prices) => order.availablePackages?.map((p) => ({ ...p, price: prices[p.code] }))),
      map((availablePackages) => ({ ...order, availablePackages })),
    );
  }
}
