<mat-toolbar *ngIf="getAssumedUser as assumedUser" class="mat-elevation-z1">
  <ng-container>
    <mat-toolbar-row class="px-4 py-1 bg-warn fx-row x-space-between y-center" id="obo-row">
      <div class="mat-body-1 m-0">

        <!-- Impersonating user: -->
        <span>
          ACTING ON BEHALF OF: {{assumedUser.profile?.preferredFirstName}} {{assumedUser.profile?.preferredLastName}}
        </span>

        <!-- Email Link -->
        <a *ngIf="assumedUser.profile?.preferredEmail" [href]="'mailto:' + assumedUser.profile.preferredEmail" [style.color]="'inherit'">
          (<u>{{assumedUser.profile.preferredEmail}}</u>)
        </a>

        <!-- Metro Name -->
        <span *ngIf="assumedUser.profile?.office?.metro?.metroName">
          - {{assumedUser.profile?.office?.metro?.metroName}}
        </span>

      </div>

      <!-- End Impersonation Button -->
      <button aria-label="Close" class="on-behalf-of" id="btn-exit-obo" mat-button (click)="endImpersonation()">
        <div class="fx-row y-center fx-gap-sm">
          <mat-icon class="material-icons-outlined">block</mat-icon>
          <span>Exit Acting Mode</span>
        </div>
      </button>
    </mat-toolbar-row>
  </ng-container>
</mat-toolbar>


<!-- RSC Header for LC & DC apps. Currently RSC package doesn't support property binding -->
<!-- So, we need to set app-key values on the tags -->

<div *ngIf="AppService.isListingConciergeProduct" class="fx-row x-end y-center fx-gap-md">
  <rsc-header *ngIf="mounted" app-key="30a0266a-6e65-46c6-9af4-229ca9b1ed35" full-width-layout="true" header-size-update="true"
              profile-menu="true" [attr.brand-key]="brandKey">
    <lc-notifications-menu />
  </rsc-header>
</div>
<div *ngIf="AppService.isDesignConciergeProduct" class="fx-row x-end y-center fx-gap-md">
  <rsc-header *ngIf="mounted" app-key="2e9b20eb-1945-46f9-a394-a68418ddd647" full-width-layout="true" header-size-update="true"
              profile-menu="true" [attr.brand-key]="brandKey">
    <lc-notifications-menu />
  </rsc-header>
</div>
