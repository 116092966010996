export class Mask {
  constructor(public mask: string, public pattern: { [character: string]: { pattern: RegExp, optional?: boolean } }, public allowNegative?: boolean) {
  }
}

export class Masks {
  public static phoneNumber: Mask = new Mask('#00.000.0000', { '#': { pattern: new RegExp('\[2-9\]') }, 0: { pattern: new RegExp('\[0-9\]') } });
  public static wholeNumber: Mask = new Mask('0*', { 0: { pattern: new RegExp('\[0-9\]') } });
  public static decimalWithWholeNumber: Mask = new Mask('separator.2', null);
  public static negativePrice: Mask = new Mask('separator.2', null, true);
  public static threeDigitWholeNumber: Mask = new Mask('000', { 0: { pattern: new RegExp('[0-9]') } });
  public static fourDigitWholeNumber: Mask = new Mask('0000', { 0: { pattern: new RegExp('[0-9]') } });
}
