import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AccessToken, OktaAuth } from '@okta/okta-auth-js';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { map, catchError, delay } from 'rxjs/operators';
import { Location } from '@angular/common';
import { UserService } from '../user.service';
import { User } from '../../models/user.model';
import { AppService } from '../app.service';
import { UserActivityService } from '../user-activity.service';
import { CookieService } from '../cookie.service';

@Injectable()
export class OktaAuthenticationProvider {
  readonly oktaAuth: OktaAuth;
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  isNewLogin$: Observable<boolean> = this.isAuthenticatedSubject.asObservable();
  protected get accessToken(): string {
    return this.oktaAuth.getAccessToken();
  }
  protected expiresAt: number;
  protected sessionEndWarningInMins = 5;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private userService: UserService,
    private userActivityService: UserActivityService,
    private location: Location,
    private cookieService: CookieService,
    private store: Store<any>,
  ) {
    const secureCookieflag = !(
      (AppService.get('oktaRedirectUri')?.indexOf('remotedev') ?? -1) >= 0
      || (AppService.get('oktaRedirectUri')?.indexOf('localhost') ?? -1) >= 0);
    try {
      this.oktaAuth = new OktaAuth({
        clientId: AppService.get('oktaClientId'),
        issuer: AppService.get('oktaIssuer'),
        redirectUri: AppService.get('oktaRedirectUri'),
        tokenManager: {
          autoRenew: true,
          expireEarlySeconds: 120,
          storageKey: 'lc-token-storage',
          storage: 'localStorage',
          syncStorage: false,
        },
        maxClockSkew: 60 * 60 * 24,
        cookies: { secure: secureCookieflag },
      });
    } catch (e) {
      console.log(e);
    }
    this.oktaAuth.start();
    this.expiredToken(router, this.expiresAt, store);
  }

  public isAuthenticated(): boolean {
    return this.accessToken && this.isAuthenticatedSubject.value;
  }

  public async isAuthenticated$(): Promise<boolean> {
    if (this.isAuthenticated()) {
      // Already retrieved login info. Just return
      return true;
    }

    const accessToken = await this.oktaAuth.tokenManager.get('accessToken');
    if (accessToken) {
      // If we already have an access token, try login with this. Otherwise,  return false
      let isAuthenticated = false;
      try {
        isAuthenticated = await this.handleAuthentication()
          .then(() => true)
          .catch(() => false);
      } catch {
        // Token is no longer valid, return false
        return false;
      }
      return isAuthenticated;
    }
    return false;
  }

  public redirectToLogin() {
    const brand = this.getBrand();
    if (brand) {
      this.router.navigate(['/okta/login'], { queryParams: { brand } });
    } else {
      this.router.navigate(['/okta/login']);
    }
  }
  private getBrand() {
    const search = this.location.path().split('?')[1];
    const params = new URLSearchParams(search);
    console.info(`Query parameters ${params}`);
    const brand = params.get('brand') ?? this.cookieService.getCookie('brand');
    return brand?.toLowerCase();
  }

  public login() {
    const config: any = {
      responseType: ['id_token', 'token'],
      scopes: ['offline_access', 'openid', 'email', 'profile', 'lcapiauth'],
    };

    const brand = this.getBrand()?.toLowerCase();
    const cbBrands = ['cb', 'cbr', 'cba'];
    const altBrands = ['bhg', 'era'];

    if (!AppService.isPhotoApp) {
      // If we are not in the photo app, use the branded login
      if (cbBrands.includes(brand)) {
        config.idp = AppService.get('brandedLoginPageIDP');
      } else if (altBrands.includes(brand)) {
        config.idp = AppService.getByString(`${brand}BrandedLoginPageIDP`);
      }
    }

    this.oktaAuth.token.getWithRedirect(config);
  }

  public async logout() {
    this.setBrandCookie();
    this.isAuthenticatedSubject.next(false);
    this.userService.clearUser('currentUser');
    if (this.accessToken === undefined) {
      this.oktaAuth.tokenManager.clear();
      await this.oktaAuth.closeSession();
      return;
    }
    let retryFlag = true;
    const maxLogoutRetries = AppService.get('maxLogoutRetries');
    const logoutRetryDelay = AppService.get('logoutRetryDelay');
    let retryCount = 0;

    while (retryFlag) {
      try {
        // logs the user out of their Okta session
        await this.oktaAuth.signOut({
          postLogoutRedirectUri: `${window.location.origin}/logout`,
        });
        retryFlag = false;
        console.log('User logout was successful');
      } catch (e) {
        console.log('logout error', e);
        await of().pipe(delay(logoutRetryDelay)).toPromise();
        retryFlag = maxLogoutRetries > retryCount;
        retryCount += 1;
      }
    }
    this.oktaAuth.tokenManager.clear();
    // We are logged out so don't need to watch anymore
    // this.oktaAuth.stop();
    this.userActivityService.stopWatching();
  }

  public getToken(): string {
    this.oktaAuth.tokenManager
      .get('accessToken')
      .then((token: AccessToken) => {
        if (token) {
          return token.accessToken;
        }
        console.log('Token has expired. Returning null');
        return null;
      })
      .catch((err) => {
        console.log('getAccessToken error..', err);
      });
    return this.accessToken;
  }

  /**
   * Tries to retrieve the token from the tokenManager, otherwise retrieves it from the url parameters.
   * Updates the tokenExpiration date and returns the new accessToken
   */
  private async updateAccessToken() {
    let accessToken: string;
    // See if we already have an okta token
    const tokenManagerResponse = await this.oktaAuth.tokenManager.get('accessToken') as AccessToken;
    if (tokenManagerResponse) {
      accessToken = tokenManagerResponse.accessToken;
      this.expiresAt = tokenManagerResponse.expiresAt;
    }

    if (!accessToken) {
      // Didn't have a token, we probably got here from the redirect after okta login
      // Get token from the url that okta login generated
      try {
        const response = await this.oktaAuth.token.parseFromUrl();
        accessToken = response.tokens.accessToken.accessToken;
        this.oktaAuth.tokenManager.add('accessToken', response.tokens.accessToken);
        this.expiresAt = response.tokens.accessToken.expiresAt;
        this.oktaAuth.tokenManager.add('idToken', response.tokens.idToken);
        console.info('Authentication tokens have been updated');
      } catch (e) {
        if (e.message.indexOf('not assigned') > -1) {
          // User is not assigned to the client application
          console.error(`User is not assigned to the application: ${JSON.stringify(e)}`);
          throw new Error(e.message);
        } else {
          console.error(`Error when getting token from url: ${JSON.stringify(e)}`);
          throw new Error(`${AppService.get('applicationName')}: ${e.message}`);
        }
      }
    }

    if (!accessToken) {
      throw new Error('Did not get access token from parsed url');
    }
    return accessToken;
  }

  public async handleAuthentication(isPostLogin?: boolean) {
    const accessToken = await this.updateAccessToken();
    const user = isPostLogin === true
      ? await this.getUserAfterLogin(accessToken)
      : await this.getCurrentUser(accessToken);

    if (user) {
      // Renew token failing because of third party cookies
      // To move to the production we are reverting the changes
      try {
        // // Successful authentication
        // this.accessToken = tokenRenewResponse.accessToken;
        await this.userService.onAuthenticated(user);
        this.initializeTokenListeners();
        this.isAuthenticatedSubject.next(true);
      } catch (e) {
        console.error(`Error renewing access token after user update:${JSON.stringify(e)}`);
        throw e;
      }
    }
    // this.sessionExpiryWarning();
    // We have a user, start monitoring activity
    this.userActivityService.startWatching();
  }

  /**
   * If the user is already logged in, get the current-user from persist.
   * This will not update profile information or create any login audits
   */
  private async getCurrentUser(accessToken: string): Promise<User> {
    // Get the user information from LC server
    const route = `${AppService.get('persistBaseURL')}user/current-user/`;
    const headers = { Authorization: `Bearer ${accessToken}` };
    return await this.httpClient
      .get(route, { headers })
      .pipe(
        map((user) => new User(user)),
        catchError((error) => {
          console.error(`Error getting current user using auth token: ${JSON.stringify(error)}`);
          throw error;
        }),
      )
      .toPromise();
  }

  /**
   * Retrieves the user information after logging into the system.
   * This method will also update profile information and audit fields
   * on the backend.
   */
  private async getUserAfterLogin(accessToken: string): Promise<User> {
    const route = `${AppService.get('persistBaseURL')}user/login`;
    const headers = { Authorization: `Bearer ${accessToken}` };
    return await this.httpClient
      .get(route, { headers })
      .pipe(map((user) => new User(user)))
      .pipe(
        map((user) => new User(user)),
        catchError((error) => {
          console.error(`Error getting current user using auth token: ${JSON.stringify(error)}`);
          throw error;
        }),
      )
      .toPromise();
  }

  /**
   * Registers listeners for okta events.
   * This is necessary for automatic token refreshes handled by okta.
   * Error and expired events shouldn't happen, but register them so we can log if they do happen.
   */
  private initializeTokenListeners() {
    this.oktaAuth.tokenManager.on('renewed', this.renewedToken);
    this.oktaAuth.tokenManager.on('error', this.tokenError);
  }

  private expiredToken(router, expiresAt, store) {
    this.oktaAuth.tokenManager.on('expired', async (key, expiredToken) => {
      if (key === 'accessToken') {
        console.log(' TRACKING TOKENS expiredToken access Token', expiredToken);
      }
    });
  }

  private async renewedToken(key, newToken, oldToken) {
    if (key === 'accessToken') {
      console.log(' TRACKING TOKENS old access Token', oldToken);
      console.log(' TRACKING TOKENS newToken', newToken);
    }
    if (key === 'idToken') {
      console.log(' TRACKING TOKENS old idToken Token', oldToken);
      console.log(' TRACKING TOKENS idToken newToken', newToken);
    }
  }

  private tokenError(err) {
    console.warn(`Token error reported by tokenManage:${JSON.stringify(err)}`);
  }

  setBrandCookie() {
    const currentUser = this.userService.getCurrentUser();
    const brand = currentUser?.profile?.office?.company?.brandCode;
    if (!brand) return;

    this.cookieService.setCookie('brand', brand, { path: '/', expires: 0 });
  }
}
