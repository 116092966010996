<!-- TODO: This form group is the same as billing and shipping component.html-->
<form class="fx-column" [formGroup]="formGroup">
  <lc-textbox class="mt-3" idPrefix="full-name" label="Full Name" placeholder="Your Full Name" [formControl]="formGroup.get('fullName')" />

  <!-- Address Section -->
  <lc-address-details-form [formGroup]="formGroup.get('address')" />

  <lc-textbox idPrefix="company" label="Company (Optional)" placeholder="Company" [formControl]="formGroup.get('company')" />

  <div lcClass.gt-sm="fx-row fx-gap-md" lcClass.lt-md="fx-column">
    <ng-container [formGroup]="formGroup.get('phoneNumber')">
      <lc-phone-number class="fx-1-1" idPrefix="phone" label="Phone" [formControl]="formGroup.get('phoneNumber').get('number')" />
    </ng-container>
    <lc-textbox class="fx-1-1" idPrefix="email" label="Email (Optional)" [formControl]="formGroup.get('email')" />
  </div>
</form>

