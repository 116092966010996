<lc-base-dialog [subheader]="data?.subheader" [title]="data?.header || 'Select a User'" (actionClicked)="onActionClicked($event)">
  <ng-template #content>
    <lc-card-select class="hide-form-borders" [formControl]="value" [options]="options" [size]="150">
      <ng-template #image let-option="option">
        <mat-icon class="xl material-icons-outlined">{{option?.value === 'current-user' ? 'person' : 'supervisor_account'}}</mat-icon>
      </ng-template>
    </lc-card-select>
  </ng-template>
  <ng-template #actions>
    <div class="w-100 fx-row x-end">
      <button class="continue" color="primary" mat-raised-button (click)="onContinue()">Continue</button>
    </div>
  </ng-template>
</lc-base-dialog>
