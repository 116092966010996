<ng-container *ngIf="form">
  <div class="pt-1 pb-1">
    <span class="mat-subheading-2 font-weight-bold">Search Orders</span>
  </div>
  <!-- Address and Area search container-->
  <div lcClass.gt-sm="fx-row fx-gap-sm" lcClass.lt-md="fx-column">
    <!-- Address Search Filter-->
    <lc-textbox class="fx-1-1-0" idPrefix="search" label="Search by Address" placeholder="Search by Address" [formControl]="form.search" />

    <!-- Area Filter-->
    <lc-multiselect class="fx-1-1-0" idPrefix="area" label="Area" [displayOptions]="areaDisplayOptions"
      [options]="areas" [(ngModel)]="form.selectedAreas" (openedChange)="onAreaClosed($event)">
      <ng-template #optionTpl let-item="item">
        <div><span class="status-text option-title">{{item}}</span></div>
      </ng-template>
    </lc-multiselect>
  </div>

  <div lcClass.gt-sm="fx-row fx-gap-sm" lcClass.lt-md="fx-column">
    <!-- Status Filter-->
    <lc-multiselect class="fx-1-1-0" displayPath="label" idPrefix="status" label="Filter by:"
      [displayOptions]="statusDisplayOptions" [options]="form.statusOptions" [(ngModel)]="form.selectedStatuses"
      (openedChange)="onStatusClosed()">
      <ng-template #optionTpl let-item="item">
        <div>
          <span class="ui-icon ui-icon-status-bullet" [style]="{'background-color': item.color}"></span>
          <span class="status-text option-title">{{item.label}}</span></div>
      </ng-template>
    </lc-multiselect>

    <!-- Date Filter-->
    <lc-multiselect
      #dateSelect
      class="fx-1-1-0"
      displayPath="display"
      dropdownClass="date-filter"
      idPath="text"
      idPrefix="date"
      label="Dates"
      multiple="false"
      [displayOptions]="dateDisplayOptions"
      [options]="form.dateOptions"
      [(ngModel)]="form.selectedDateOption"
      (openedChange)="onDateToggled($event)"
    >

      <ng-template #optionTpl let-item="item">
        <div>
          <span class="option-title">{{item.text}}</span>
        </div>

        <lc-datepicker
          *ngIf="item.text === 'Custom'"
          #range
          [defaultEndDate]="item.endDate"
          [defaultStartDate]="item.startDate"
          [enableRangeSelection]="true"
          (click)="form.selectedDateOption = item; $event.stopPropagation()"
          (dateChange)="item.startDate = $event.start; item.endDate = $event.end; dateSelect.updateDisplay();"
        />
      </ng-template>
    </lc-multiselect>
  </div>

  <div lcClass.gt-sm="fx-row fx-gap-sm" lcClass.lt-md="fx-column">
    <div class="fx-row fx-gap-sm fx-fill">
      <lc-dropdown *ngIf="form.userFilters === 'both'" class="users fx-1-1-0" displayPath="viewValue" label="Search Users By:" valuePath="value" [options]="form.userFilterOptions" [(ngModel)]="form.selectedUserFilterOption" />

      <lc-agent-search *ngIf="form.selectedUserFilterOption === 'agent'" class="fx-1-1-0" label="Agent:" placeholder="Search by Agent name" [formControl]="form.agentSearch"
        [searchBy]= "form.selectedUserFilterOption" [selectedIds]="form.selectedAgentIds" (selectedChanged)="onAgentsSelected($event)" />

      <lc-agent-search *ngIf="form.selectedUserFilterOption === 'lcc'" class="fx-1-1-0" label="Coordinator:" placeholder="Search by Coordinator name"
        [formControl]="form.coordinatorSearch" [searchBy]= "form.selectedUserFilterOption"
        [selectedIds]="form.selectedCoordinatedIds" (selectedChanged)="onCoordinatorsSelected($event)" />
    </div>

    <lc-multiselect *ngIf="form.showAssignment" class="fx-1-1-0" idPrefix="assignment" label="Assigned to:"
      [displayOptions]="assignmentDisplayOptions" [formControl]="form.getControl('assignments')"
      [options]="form.assignmentOptions" (openedChange)="onAssignmentsClosed()" />
  </div>

</ng-container>
