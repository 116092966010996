<form [formGroup]="form">

  <mat-horizontal-stepper #stepper class="h-100 bg-transparent hide-header p-0 fx-1-1-0"
                          [linear]="true">


    <ng-container *ngFor="let group of form.groups; let first = first; let last = last;">

      <mat-step [label]="group.group.header" [stepControl]="group.formGroup">

        <div *ngFor="let control of group.sortedControls;" [formGroup]="group">
          <ng-container *ngIf="control.input as input" [ngSwitch]="input.inputType">

            <lc-multiselect *ngSwitchCase="['multi-select', 'select'].includes(input.inputType) ? input.inputType : ''"
                            [displayOptions]="{display: '', multiDisplay: '', displayMultiCount: true}"
                            [displayPath]="'label'" [formControl]="control"
                            [hint]="input.hint"
                            [label]="input.label" [multiple]="input.inputType === 'multi-select'"
                            [options]="input.options"
                            [valuePath]="'value'" />

            <lc-textbox *ngSwitchCase="'textbox'" [formControl]="control" [hint]="input.hint"
                        [label]="input.label" [placeholder]="input.placeholder" />

            <lc-checkbox *ngSwitchCase="'checkbox'" [formControl]="control" [hint]="input.hint"
                         [label]="input.label" />

            <lc-textarea *ngSwitchCase="'textarea'" [formControl]="control" [hint]="input.hint"
                         [label]="input.label" [placeholder]="input.placeholder" [rows]="3" />

            <lc-radio-group *ngSwitchCase="'radio-group'"
                            class="hide-form-borders"
                            layout="column" [formControl]="control" [label]="input.label"
                            [options]="input.options" />

            <span *ngSwitchDefault>
              Missing {{option.inputType}}
            </span>
          </ng-container>
        </div>

        <mat-divider class="w-100 position-relative mb-3" />

        <div class="form-button-container fx-row fx-gap-xs">
          <button *ngIf="form.groups.length > 1" class="fx-1-1-0" mat-stroked-button matStepperPrevious [disabled]="first">Back</button>
          <button *ngIf="!last" class="fx-1-1-0" color="primary" mat-stroked-button matStepperNext>Next</button>
          <button *ngIf="last" class="fx-1-1-0" color="primary" mat-stroked-button (click)="onDone(group)">Complete</button>
        </div>


      </mat-step>
    </ng-container>
  </mat-horizontal-stepper>

</form>