<mat-form-field appearance="outline" floatLabel="always" [class.mat-form-field-readonly]="readonly" [class.ng-valid]="errors === ''" [id]="idPrefix + '-form-field'">

    <!-- Label -->
    <mat-label [id]="idPrefix + '-label'">
      <template *ngIf="labelTemplate; else defaultLabel" [ngTemplateOutlet]="labelTemplate"></template>
      <ng-template #defaultLabel>
        {{label}}
      </ng-template>
    </mat-label>
  
    <!-- Input -->
    <input matInput [formControl]="formControl" [matAutocomplete]="auto" [placeholder]="placeholderText">
    
    <mat-autocomplete #auto="matAutocomplete" panelWidth="auto" (optionSelected)='updateSelection($event.option.value)'>
        <ng-container *ngIf="autoCompleteOptions && autoCompleteOptions.length > 0">
            <mat-option *ngFor="let data of autoCompleteOptions" [title]="data.text" [value]="data">
            <span>{{ data.text }}</span>
            </mat-option>
        </ng-container>
    </mat-autocomplete>
    <!-- Hint-->
    <mat-hint class="w-100" [id]="idPrefix + '-hint'">
      <template *ngIf="hintTemplate; else defaultHint" [ngTemplateOutlet]="hintTemplate"></template>
      <ng-template #defaultHint>
        {{hint}}
      </ng-template>
    </mat-hint>
  
    <!-- Error -->
    <mat-error *ngIf="formControl && formControl.invalid" [id]="idPrefix + '-error'" [innerHTML]="errors" />
  
    <!-- Suffix -->
    <div *ngIf="suffix || suffixTemplate" matSuffix>
      <template *ngIf="suffixTemplate; else defaultSuffix" [ngTemplateOutlet]="suffixTemplate" ></template>
      <ng-template #defaultSuffix>
        <span *ngIf="suffix">{{suffix}}</span>
      </ng-template>
    </div>
  </mat-form-field>
  
  