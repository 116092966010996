import { Component, Input } from '@angular/core';

@Component({
  selector: 'lc-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  standalone: false,
})
export class AvatarComponent {
  @Input() size: number = 40;
  @Input() isVip = false;
  @Input({ required: true }) photoUrl!: string;
}
