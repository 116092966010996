<h6 *ngIf="label" class="mt-0 mat-body-2 card-label" [id]="idPrefix + '-label'">{{label}}</h6>

<!--Card container-->
<div class="scroll-container clean-scroll-bar overyflow-y-auto">
  <div *ngIf="options$ | async as options" class="card-container fx-column" [id]="idPrefix + '-card-container'">

    <!--Cards-->
    <ng-container *ngIf="options">
      <div *ngFor="let option of options; let i = index" class="ml-0 "
        (mouseenter)="onHover(option)" (mouseleave)="onHover()">
        <!-- Each card is 125 x 125 with a 5 px padding ==> 115 x 115 inside-->
        <a class="mat-elevation-z1 card-option clickable mt-2 fx-row y-center fx-gap-sm text-default"
          [class.disabled]="!option.isEnabled"
          [id]="parseId('card-option-' + (option.id || option.text || i))"
          [ngClass]="{'option-selected': option.isSelected}" (click)="selectOption(options, option)">

          <!--Card Image 95 of the 115 px-->
          <div class="image-container ml-2 img-div-width fx-column x-center">

            <!-- Card Image -->
            <template *ngIf="imageTemplate; else defaultImage" class="logo-img" [ngTemplateOutlet]="imageTemplate"
              [ngTemplateOutletContext]="{option: option}"></template>
            <ng-template #defaultImage>
              <div *ngIf="label === 'Agent Type'">
                <button *ngIf="!option.imageSource.includes('/')" class="md small-icon" color="primary" mat-mini-fab [disabled]="!option.isSelected || option.isSelected"
                   [ngClass]="{'bg-primary': option.isSelected}">
                  <span *ngIf="option.isSelected" class="material-icons" [id]="option.imageSource">{{option.imageSource}}</span>
                  <span *ngIf="!option.isSelected" class="material-icons" [id]="option.imageSource">{{option.imageSource}}</span>
                </button>
              </div>
              <div *ngIf="label !== 'Agent Type' || option.imageSource.includes('/')">
                <img *ngIf="!option.isSelected && option.imageSource" class="logo-img"
                [src]="option.imageSource" />
              <img *ngIf="option.isSelected && option.selectedImageSource" class="logo-img"
                [src]="option.selectedImageSource" />
              </div>
            </ng-template>
          </div>
          <!--Display Label: Remaining 20 of the 115px-->

          <!-- Card Content -->
          <span class="description-text ml-2 my-0 mat-body-1 text-wrap fx-column y-center fx-1-1-0" [class.link-color]="option.isSelected">
            <template *ngIf="footerTemplate; else defaultFooter" [ngTemplateOutlet]="footerTemplate"
              [ngTemplateOutletContext]="{option: option}"></template>
            <ng-template #defaultFooter>
              {{ option.text }}
            </ng-template>
          </span>

          <!-- Actions -->
          <mat-icon *ngIf="!option.model?.isBundled" [color]=" option.isSelected ? 'primary' : undefined">
            <ng-container *ngIf="option.isSelected; else unchecked">
              {{maxSelect === 1 ? 'radio_button_checked' : 'check_box'}}
            </ng-container>
            <ng-template #unchecked>
              {{maxSelect === 1 ? 'radio_button_unchecked' : 'check_box_outline_blank'}}
            </ng-template>
          </mat-icon>


        </a>
      </div>
    </ng-container>
  </div>
</div>
<small class="invalid-feedback d-block" [id]="idPrefix + '-error'" [innerHTML]="errors || '&nbsp;'"></small>
