
<mat-form-field appearance="outline" [floatLabel]="'always'">

  <mat-label [id]="idPrefix + '-label'">{{label}}</mat-label>
  <mat-select #select hideSingleSelectionIndicator="true" matInput [attr.aria-owns]="aria"
              [errorStateMatcher]="matcher" [formControl]="formControl" [id]="idPrefix + '-input'"
              [placeholder]="placeholder" (selectionChange)="onSelectionChanged($event)">
    <mat-option *ngIf="emptyOptionDisplay" [class.disabled-option]="emptyOptionDisabled"
                [disabled]="emptyOptionDisabled" [value]="emptyOptionValue">
      {{ emptyOptionDisplay }}
    </mat-option>

    <mat-select-trigger>
      <ng-template *ngIf="selected && displayTemplate; else triggerLabel"
                   [ngTemplateOutlet]="displayTemplate"
                   [ngTemplateOutletContext]="{item: selected.model}" />

      <ng-template #triggerLabel>
        {{selected?.label || placeholder }}
      </ng-template>
    </mat-select-trigger>

    <mat-option *ngFor="let option of dropdownOptions" [disabled]="disabledPath && option?.value[disabledPath]" [id]="option.id"
                [ngClass]="{ 'line-height-unset': optionTemplate }"
                [value]="option.value">


      <ng-container *ngIf="select.panelOpen; else collapsed">
        <ng-container *ngIf="optionTemplate || displayTemplate; else defaultOption"
                      [ngTemplateOutlet]="optionTemplate || displayTemplate"
                      [ngTemplateOutletContext]="{item: option.model}" />
      </ng-container>

      <ng-template #collapsed>
        <ng-container *ngIf="displayTemplate; else defaultOption"
                      [ngTemplateOutlet]="displayTemplate"
                      [ngTemplateOutletContext]="{item: option.model}" />
      </ng-template>

      <ng-template #defaultOption>
        <span class="option-title">{{option.label}} </span>
      </ng-template>
    </mat-option>

  </mat-select>
  <mat-hint [id]="idPrefix + '-hint'">{{hint}}</mat-hint>
  <mat-error *ngIf="formControl && formControl.invalid" [id]="idPrefix + '-error'"
             [innerHTML]="errors" />

  <!-- Fake input used to handle autofill changes. Needs to hide behind the screen. display: none does not work -->
  <input class="hidden-autofill-input" matInput tabindex="-1" [formControl]="formControl"
         (change)="onAutoFill($event)">

</mat-form-field>
