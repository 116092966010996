export enum LotSizeUnitOfMeasure {
  sqft = 'Sq. Ft.',
  acres = 'Acres',
}

export const LotSizeUnitOfMeasures: LotSizeUnitOfMeasure[] = Object.values(LotSizeUnitOfMeasure);

export const lotSizeUnitLabels: Record<LotSizeUnitOfMeasure, string> = Object.freeze({
  [LotSizeUnitOfMeasure.sqft]: 'Square feet',
  [LotSizeUnitOfMeasure.acres]: 'Acres',
});
