<div class="editor" id="editor-control-value"
     [class.disabled]="disabled"
     [ngClass]="!setBorder ? '' : isHTMLOverflow ? 'danger-border' : ''"
     (lcClickOutside)="onClickedOutside($event)"
>
  <quill-editor
    #myEditor
    class="w-100"
    id="quill-editor-value"
    [bounds]="'self'"
    [class.invalid]="errors"
    [disabled]="disabled"
    [format]="quillFormat"
    [formats]="quillFormats"
    [modules]="modules"
    [styles]="{'height': minimumHeight}"
    [(ngModel)]="value"
    (onContentChanged)="onTextChange($event)"
    (onEditorCreated)="setFocus($event)"
    (onSelectionChanged)="onBlur($event)">
    <div quill-editor-toolbar>
      <div class="editable-toolbar">
        <div  class="editable-toolbar-inner" [ngClass]="{ 'hide-toolbar': !quillToolbarEnabled }">
          <div class="fx-row y-center">
            <div class="toolbar-buttons fx-1-0">
              <div class="ql-formats quill-editor-options">
                <div *ngIf="!isHeader">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button>

                  <button class="ql-list" type="button" value="ordered"></button>
                  <button class="ql-list" type="button" value="bullet"></button>

                  <select class="ql-align">
                    <option value="left"></option>
                    <option value="center"></option>
                    <option value="right"></option>
                  </select>

                  <button class="ql-link"></button>
                  <button *ngIf="statistics?.maximum && !isAgentApp" class="summarize-button"
                          [disabled]="hasSummarized"
                          (click)="onSummarizeClicked()">
                    <mat-icon class="summarize">auto_awesome</mat-icon>
                  </button>
                  <button *ngIf="(aiFeatureIsEnabled$ | async) && showUndo" color="primary"
                          mat-button style="font-size: x-small; line-height: 10px; min-width: 30px;" type="button" [disabled]="undoDisabled" (click)="onUndo()">
                    Undo
                  </button>
                </div>
              </div>

            </div>
            <div *ngIf="statistics?.maximum">
              <div class="fx-row">
                <div class="approx-text" style="align-content: center; padding-right: 8px;" [ngStyle]="{'color': statistics.percentage >= maxPercentage ? '#de342b' : '#000'}">Approx {{statistics.maximum}} chars </div>
                <div class="mr-1 fx-0-0-auto">
                  <mat-progress-spinner class="spinner-right" mode="determinate" [color]="statistics.percentage >= maxPercentage ? 'warn': 'primary'"
                                        [diameter]="spinnerDiameter" [value]="statistics.percentage" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </quill-editor>
</div>
