<mat-form-field appearance="outline" floatLabel="always" [class.has-selected]="hasSelection" [id]="idPrefix + '-input'">

  <!-- Prefix -->
  <div *ngIf="prefix || prefixTemplate" matPrefix>
    <template *ngIf="prefixTemplate; else defaultPrefix" [ngTemplateOutlet]="prefixTemplate" ></template>
    <ng-template #defaultPrefix>
      <span *ngIf="prefix">{{prefix}}</span>
    </ng-template>
  </div>

  <mat-label>{{label}}</mat-label>
  <input class="d-none" matInput [errorStateMatcher]="matcher" [formControl]="formControl">

  <mat-chip-grid #chipList aria-label="Chips Selection" class="w-100">
    <ng-container *ngFor="let option of chipOptions">
      <mat-chip-row
        *ngIf="option.isSelected"
        [ngClass]="size"
        [removable]="true"
        (removed)="onItemRemoved(option)">
        <ng-container *ngIf="tagTemplate?.elementRef === null; else tagTmp">
          {{option.label || option.value}}
        </ng-container>

        <ng-template #tagTmp>
          <template [ngTemplateOutlet]="tagTemplate" [ngTemplateOutletContext]="{item: option.value, label: option.label}"></template>
        </ng-template>

        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
    </ng-container>
    <input
      #chipInput
      required
      [disabled]="!canAdd"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [placeholder]="hasSelection ? '' : placeholder"
      (input)="onUserInput($event)"
      (matChipInputTokenEnd)="onInputAdd($event)">
  </mat-chip-grid>

  <mat-hint>{{hint}}</mat-hint>
  <mat-error *ngIf="errors" [id]="idPrefix + '-error'" [innerHTML]="errors" />
  <!-- <mat-error [id]="idPrefix + '-error'" *ngIf="inputFormControl && inputFormControl.invalid && errors" [innerHTML]="errors"></mat-error> -->

  <mat-autocomplete #auto="matAutocomplete" class="z-2000" (change)="selectedChanged.emit($event)" (optionSelected)="onSelected($event)">
      <!-- No Results Option: Displays when 0 results-->
      <mat-option *ngIf="chipOptions?.length === 0 && noResultsDisplay" class="no-results" disabled>
        {{noResultsDisplay}}
      </mat-option>

      <!-- Results Options -->
      <ng-container *ngFor="let option of chipOptions">
        <mat-option *ngIf="!option.isSelected" [value]="option">
          <ng-container *ngIf="optionTemplate?.elementRef === null; else optTemplate">
            {{option.label}}
          </ng-container>
          <ng-template #optTemplate>
            <template [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{item: option.model}"></template>
          </ng-template>
        </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
