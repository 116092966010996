import {
  Component, OnInit, Attribute, Input, Output, EventEmitter, OnChanges, SimpleChanges, forwardRef, TemplateRef, ContentChild,
} from '@angular/core';
import {
  FormControl, Validators, ControlValueAccessor, NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  TeamMemberService, User, LOGGEDINUSER, ProfileInfo, OfficeMemberService, AppService,
} from '@lc/core';
import { Observable, timer } from 'rxjs';
import {
  switchMap, withLatestFrom, map, debounce,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';

@Component({
  selector: 'lc-agent-search',
  templateUrl: './agent-search.component.html',
  styleUrls: ['./agent-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AgentSearchComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class AgentSearchComponent implements ControlValueAccessor, OnInit, OnChanges {
  memberSearch$: Observable<ProfileInfo[]>;
  searchControl: FormControl;
  appkey: string;
  noResultDisplayText: string;

  @Input()
    idPrefix = 'members';

  @Input()
    formControl: FormControl;

  @Input()
    selected: ProfileInfo[] = [];

  @Input()
    selectedIds: string[] = [];

  @Input()
    blacklistedIds: string[] = [];

  @Input()
    excludeTeamMembers: boolean = false;

  @Input()
    onlyDelegatedUsers: boolean = false;

  @Input()
    searchBy: string = '';

  @Input()
    filterRealEstateRole: string = '';

  @Input()
    filterLicenseNumber: boolean = false;

  @Input()
    office: boolean = false;

  @Input()
    delegate: boolean = false;

  @Output()
  readonly selectedChanged = new EventEmitter<ProfileInfo[]>();

  readonly validators: Validators;

  constructor(
    @Attribute('max') public max: number,
    @Attribute('label') public label: string = 'Members',
    @Attribute('placeholder') public placeholder = 'Add member',
    @Attribute('emptyPlaceholder') public emptyPlaceholder = 'Add new members',
    private teamMemberService: TeamMemberService,
    private officeMemberService: OfficeMemberService,
    private store: Store<any>,
  ) {
    this.max = this.max ? (+this.max) : undefined;
  }

  ngOnInit() {
    this.appkey = AppService.get('applicationName');
    this.noResultDisplayText = `No user found. Please ensure the user you are searching for has logged into ${this.appkey} and completed their profile`;
    this.initializeSearch();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedIds) {
      if ((this.selectedIds || []).length > 0 && !this.delegate) {
        // We need to find these agent/names to select them again.
        const memberService = this.office ? this.officeMemberService : this.teamMemberService;
        memberService.searchAgentsById(this.selectedIds, this.searchBy).subscribe((agents) => {
          this.updateAgents(agents, false);
        }, (error) => { throw new Error(error); });
      } else {
        this.updateAgents([], false);
      }
    }
  }

  public updateAgents(agents: ProfileInfo[], emit: boolean = true) {
    if (!this.selected) this.selected = [];
    this.selected.splice(0, this.selected.length + 1, ...agents);
    if (emit) {
      this.selectedChanged.emit(this.selected);
    }
    this.formControl.setValue(this.selected);
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this.selected = value;
    }
  }
  registerOnChange(fn) {
  }

  registerOnTouched() {}

  private initializeSearch() {
    const memberService = this.office ? this.officeMemberService : this.teamMemberService;
    this.searchControl = new FormControl();

    if (this.delegate) {
      this.memberSearch$ = this.searchControl.valueChanges.pipe(

        withLatestFrom(this.store.select(LOGGEDINUSER).pipe(map((user: User) => user))),
        debounce(() => timer(500)), // NOTE: The debounce time on the chips input is already 250 ms
        switchMap(([searchTerm, user]) => this.officeMemberService.searchAllAgents(searchTerm).pipe(
          map((members) => members.filter((member) => member.userId !== user._id)),
        )),
      );
    } else {
      this.memberSearch$ = this.searchControl.valueChanges.pipe(

        withLatestFrom(this.store.select(LOGGEDINUSER).pipe(map((user: User) => user))),
        debounce(() => timer(500)), // NOTE: The debounce time on the chips input is already 250 ms
        switchMap(([searchTerm, user]) => memberService.searchAgents(searchTerm, this.onlyDelegatedUsers, this.excludeTeamMembers, this.searchBy, this.filterLicenseNumber, this.filterRealEstateRole, this.blacklistedIds).pipe(
          map((members) => members.filter((member) => member.userId !== user._id)),
          map((members) => members.filter((member) => (this.selectedIds || []).indexOf(member._id) < 0)),
          map((members) => members.filter((member) => (this.blacklistedIds || []).indexOf(member._id) < 0)),
          map((members) => members.filter((member) => !(this.selected || []).find((s) => s.userId === member.userId))),
        )),
      );
    }
  }
}
