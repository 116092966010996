
<div class="fx-column" [formGroup]="formGroup">
  <!-- Optional Name and Company if exists -->
  <lc-textbox *ngIf="formGroup.get('fullName')" label="Full Name" placeholder="" [formControl]="formGroup.get('fullName')" />
  <lc-textbox *ngIf="formGroup.get('company')" label="Company (Optional)" placeholder="" [formControl]="formGroup.get('company')" />
  <!-- Address -->
  <lc-autocomplete label="Address 1" placeholder="Number and Street" placeholderText="Number and Street" [autoCompleteOptions]="addressOptions$ | async"  [formControl]="getControl('streetAddress1')" [isAutocomplete]="true" (optionValue)="updateAddressFields($event)" />

  <!-- Street Address2 and City Row -->
  <lc-textbox label="Address 2 (Optional)" placeholder="Address line 2" [formControl]="getControl('streetAddress2')" />
  <lc-textbox label="City" placeholder="City" [formControl]="getControl('city')" />

  <!-- State and Zip Code row (column if layout is xs)-->
  <div lcClass.gt-sm="fx-row-row fx-gap-sm" lcClass.lt-md="fx-column">
    <lc-dropdown class="state" displayPath="abbreviation" emptyOptionDisplay="Select State" label="State" lcClass.gt-sm="fx-1-1-0" placeholder="State" valuePath="abbreviation" [formControl]="getControl('state')" [options]="getStates()" />
    <lc-textbox label="Zip Code" lcClass.gt-sm="fx-1-1-0" placeholder="Zip Code" [formControl]="getControl('postalCode')" />
  </div>

  <ng-container *ngIf="formGroup.get('phoneNumber') || formGroup.get('email')">
    <div lcClass.gt-sm="fx-row fx-gap-md" lcClass.lt-md="fx-column">
      <ng-container *ngIf="formGroup.get('phoneNumber') as phoneNumberForm" [formGroup]="phoneNumberForm">
        <lc-phone-number class="fx-1-1" idPrefix="phone" label="Phone" [formControl]="phoneNumberForm.get('number')" />
      </ng-container>
      <lc-textbox  *ngIf="formGroup.get('email') as emailControl"  class="fx-1-1" idPrefix="email" label="Email (Optional)" [formControl]="emailControl" />
    </div>
  </ng-container>
</div>
