<mat-list-item *ngIf="areaName" class="py-2 bg-lightest">
    <div class="w-100 fx-row fx-gap-sm y-center" [class.x-center]="!isExpanded">

      <mat-icon class="size-34 material-icons-outlined" matTooltipPosition="right" [matTooltip]="'Area: ' + areaName" [matTooltipDisabled]="isExpanded">room </mat-icon>

      <div *ngIf="isExpanded">
        <div class="area-name mat-body-2 text-bold text-uppercase line-height-0" id="area-info">{{areaName}}</div>
        <div class="area-label mat-caption line-height-0">Area Name</div>
      </div>
    </div>
  </mat-list-item>