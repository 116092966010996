<div class="h-100 py-0 fx-column fx-gap-0">

  <!-- Title -->
  <div class="fx-row y-center x-space-between">
    <!-- If we passed in a template for the header, use that. Otherwise, use default-->
    <ng-container *ngIf="!titleTemplate; else titleTemplate">
      <div *ngIf="title || header || subheader" class="fx-column pb-3">
        <span class="m-0 mat-h2 font-weight-bold dialog-title line-height-0 ">{{ header || title}}</span>
        <span *ngIf="subheader" class="mat-body-1 dialog-subtitle mat-hint m-0 line-height-0 card-subheader">{{subheader}}</span>
      </div>
    </ng-container>

    <button *ngIf="!removeClose" class="m-0 close-button fx-0-0-auto" mat-icon-button (click)="onCloseClicked()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!-- Content -->
  <div *ngIf="!contentTemplate; else contentTemplate" class="banner-text prompt-text px-0 py-sm" mat-dialog-content [innerHTML]="message">
  </div>

  <!-- Actions -->
  <ng-container *ngIf="primaryAction || otherActions?.length || actionsTemplate">
    <div *ngIf="!actionsTemplate; else actionsTemplate" mat-dialog-actions >
      <div class="w-100 fx-row-wrap x-end y-center fx-gap-sm">
        <a *ngFor="let action of otherActions" class="cancel-btn m-0" mat-button [id]="toButtonId(action.text)" (click)="onActionClicked(action)">{{action.text}}</a>
        <button *ngIf="primaryAction" class="m-0" color="primary" mat-raised-button type="button" [id]="toButtonId(primaryAction.text)" (click)="onActionClicked(primaryAction)">{{primaryAction.text}}</button>
      </div>
    </div>
  </ng-container>
</div>
