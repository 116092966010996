<mat-form-field *ngIf="options" appearance="outline" class="hide-notched-outline" floatLabel="always" [class.small] [hideRequiredMarker]="!label">
  <mat-label [id]="idPrefix + '-label'">{{label}}</mat-label>

  <div class="card-select p-3" gdGap="20px" [gdColumns]="'repeat(auto-fit, ' + size +'px)'">

    <ng-container *ngFor="let option of options">

      <div #descriptionAnimation="descriptionAnimation" class="card-container" lcCardDescriptionAnimation [class.border-primary]="option.isSelected"
           [class.border-round]="variant !== 'slim'" [class.disabled]="option.isDisabled" [class.readonly]="option.isReadonly" [description]="option.description"
           [disableOnHover]="true" [ngClass]="variant" [toggleRef]="toggleRef" (click)="onSelect(option)">

        <ng-container [ngSwitch]="variant">
          <ng-container *ngSwitchCase="'slim'">
            <ng-container [ngTemplateOutlet]="slimCard" [ngTemplateOutletContext]="{option}" />
          </ng-container>

          <ng-container *ngSwitchDefault>
            <ng-container [ngTemplateOutlet]="defaultCard" [ngTemplateOutletContext]="{option}" />
          </ng-container>
        </ng-container>


        <mat-icon *ngIf="option.description" #descriptionToggle class="info-icon" (click)="descriptionAnimation.onClick($event)">info_outline
        </mat-icon>
      </div>
    </ng-container>


    <!-- Hidden matInput plugs into formControl -->
    <input class="d-none" matInput [errorStateMatcher]="matcher" [formControl]="formControl">
  </div>

  <mat-error *ngIf="formControl && formControl.invalid" class="error-message" [innerHTML]="errors" />
  <mat-hint *ngIf="hint">
    {{hint}}
  </mat-hint>
  <mat-hint *ngIf="maxOptions" align="end">
    {{maxOptions - (selectedValues?.length || 0)}} selections remaining
  </mat-hint>
</mat-form-field>

<ng-template #defaultCard let-option="option">
  <div *ngIf="option" class="default-card square border-round p-2 fx-column fx-gap-sm x-center" [style.height.px]="size" [style.width.px]="size">

    <!-- Label Section-->
    <div class="overflow-ellipsis w-100 text-center fx-0-1-auto">
      <span class="label mat-caption m-0" matTooltip="{{option.label}}">{{option.label}}</span>
    </div>

    <div class="w-100 fx-1-1-0 fx-row x-center y-center">
      <ng-container *ngIf="!imageTemplate; else imgTemplate">
        <ng-container *ngIf="option?.imageUrl as imageUrl; else image_fallback">
          <div class="card-image float-container fx-fill" [style.background-image]="'url(' + imageUrl + ')'">

            <button *ngIf="option?.fullSizeImageUrl" class="expand-image float top-right sm opacity-90" color="white" mat-mini-fab (click)="onFullSize(option, $event)">
              <mat-icon>open_in_full</mat-icon>
            </button>

          </div>
        </ng-container>
      </ng-container>

      <!-- Allow customization of the image -->
      <ng-template #imgTemplate>
        <template [ngTemplateOutlet]="imageTemplate" [ngTemplateOutletContext]="{option}"></template>
      </ng-template>

      <ng-template #image_fallback>
        <div class="circle"></div>
      </ng-template>

    </div>
  </div>
</ng-template>

<ng-template #slimCard let-option="option">
  <div *ngIf="option" class="slim-card" [option]="option">
    <div class="label" [style.fontSize]="'1.3rem'">{{option.label}}</div>
    <input class="d-none" matInput [errorStateMatcher]="matcher" [formControl]="formControl">
  </div>
</ng-template>


<ng-template #fullSizeImageTemplate let-option>
  <lc-base-dialog id="photo-gallery-dialog" [title]="option.label" (close)="dialogRef?.close()">

    <!--Modal Body-->
    <ng-template #content>
      <div class="image-container fx-fill">
        <img [src]="option?.fullSizeImageUrl">
      </div>
    </ng-template>
  </lc-base-dialog>
</ng-template>
