<mat-form-field appearance="outline" class="dashed-border" floatLabel="never" [class.ng-valid]="errors === ''" [id]="idPrefix + '-form-field'">
  <!-- Input -->
  <div class="fx-row x-center y-center">
    <div class="upload-container">
      <lc-file-upload #fileUpload [allowedExtensions]="allowedExtensions"
        [class.d-none]="formControl?.value" [imageUrl]="placeholderImage"
        [instructions]="placeholder" [multipleUpload]="multipleUpload"
        [photoStyle]="photoStyle" [processing]="!!uploadStatus" (uploadResult)="uploadPhoto($event)" />

      <div *ngIf="formControl?.value" class="image-border fx-column x-center y-center">
        <!-- Image Display-->
        <div class="image-container fx-row x-center y-center">
          <img [class]="photoStyle" [src]="formControl.value">
        </div>

        <div *ngIf="canEdit && formControl?.value" class="menu-button-container">

          <!-- Menu Button -->
          <button aria-label="Logo Options" class="mb-2 menu-button" color="default" mat-mini-fab [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>

          <!-- Dropdown Menu -->
          <mat-menu #menu="matMenu">
            <button *ngIf="editImage?.observers?.length" color="primary" mat-menu-item (click)="onEdit()">
              <mat-icon>edit</mat-icon>
              <span>Edit</span>
            </button>
            <button  color="primary" mat-menu-item (click)="fileUpload?.chooseFiles()">
              <mat-icon>import_export</mat-icon>
              <span>Replace</span>
            </button>
            <button  *ngIf="downloadUrl" color="primary" mat-menu-item (click)="onDownload()">
              <mat-icon>download</mat-icon>
              <span>Download</span>
            </button>
            <button color="warn" mat-menu-item (click)="onDelete()">
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>

  <!-- Hidden input used to make this a valid mat-form-field-->
  <input class="d-none" matInput [errorStateMatcher]="matcher" [formControl]="formControl">

  <!-- Hint-->
  <mat-hint class="w-100" [id]="idPrefix + '-hint'">
    {{hint}}
  </mat-hint>

  <!-- Error -->
  <mat-error *ngIf="formControl && formControl.invalid" class="mt-3" [id]="idPrefix + '-error'" [innerHTML]="errors" />
</mat-form-field>

