<mat-checkbox
  color="primary"
  [checked]="checked"
  [class.mat-checkbox-disabled]="readonly"
  [class.no-margin]="!(hint || showErrors)"
  [disableRipple]="readonly"
  [formControl]="formControl"
  [id]="idPrefix + '-form-field'"
  (change)="onChanged($event)"
  (click)="readonly ? $event.preventDefault() : null"
>
<div class="fx-column">
  <ng-content />
    <mat-label [id]="idPrefix + '-label'">{{label}}</mat-label>
    <span style="font-size:85%"
      [innerHTML]="(showErrors ? errors : null) || hint"
      [ngClass]="(showErrors && errors) ? 'mat-error' : 'mat-hint'"
    ></span>
  </div>
</mat-checkbox>
<!-- 
<div *ngIf="hint || showErrors" style="margin-left:40px; font-size: 75%; margin-top: -5px;">

</div> -->
