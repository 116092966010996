import {
  Component, Input, forwardRef, Output, EventEmitter, ContentChild, TemplateRef, SimpleChanges, OnChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSelectChange } from '@angular/material/select';
import { InputField } from '../input-field';

/**
 * The model used to display the options
 */
export interface IDropdownOption<TModel = any> {
  /** The label for the card */
  label: string;

  /** The value to set the form control to when selected */
  value: TModel;

  ariaId: string;

  id: string;

  /** Whatever value this was originally mapped to */
  model?: any;
}

@Component({
  selector: 'lc-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class DropdownComponent extends InputField implements OnChanges {
  @Input() label: string;

  @Input() placeholder = '';

  @Input() required = false;

  @Input()
    emptyOptionDisplay: string;

  @Input() emptyOptionDisabled = false;

  @Input()
    emptyOptionValue: string = '';

  @Input()
    hint: string;

  @Input()
    displayPath: string;

  @Input()
    valuePath: string;

  @Input()
    disabledPath: string;

  @Input() options: any[] = [];

  @Input() fixedHeight: boolean = true;

  @Output()
  readonly selectionChanged = new EventEmitter<any>();

  /** Used while the panel is opened */
  @ContentChild('optionTemplate') optionTemplate: TemplateRef<any>;

  /** Used for plain text */
  @ContentChild('display') displayTemplate: TemplateRef<any>;

  bypassProfileStatus: boolean;
  dropdownOptions: IDropdownOption[];
  selected?: IDropdownOption;
  aria: string;
  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.updateCardOptions();
    }
  }

  private updateCardOptions() {
    this.aria = this.getOptionAriaId();
    this.dropdownOptions = (this.options || []).map((option) => {
      const display = this.getDisplay(option);
      const value = this.valuePath ? option[this.valuePath] : option;
      return {
        id: this.getOptionId(option),
        ariaId: this.getOptionAriaId(),
        label: display,
        value,
        model: option,
      };
    });

    this.selected = this.dropdownOptions.find((opt) => opt.value === this.value);
  }

  public onSelectionChanged(event: MatSelectChange) {
    this.selectionChanged.emit(event.value);
  }

  protected override executeOnChanged(): void {
    super.executeOnChanged();
    this.selected = this.dropdownOptions?.find((opt) => opt.value === this.value);
  }

  public getDisplay(option) {
    if (option == null) { return ''; }
    return (this.displayPath ? option[this.displayPath] : option) || '';
  }

  public getOptionId(option) {
    const optionText = this.getDisplay(option);

    // Get the unique option text if there are multiple with the same name and different casing (i.e. Leasehold vs. LEASEHOLD)
    const sameTextOptions = this.options.filter((opt) => this.getDisplay(opt).toLowerCase() === optionText.toLowerCase());
    const optionIndex = sameTextOptions.indexOf(option);
    const uniqueOptionText = `option-${optionText}${sameTextOptions.length > 1 ? `-${optionIndex + 1}` : ''}`;

    return uniqueOptionText
      .replace(/\./g, '')
      .replace(/ /g, '-')
      .replace(/\\/g, '-')
      .replace(/\//g, '-')
      .replace(/[\(\)]/g, '-')
      .replace(/:/g, '-')
      .toLowerCase();
  }

  public getOptionAriaId() {
    const optionIds = (this.options || [])?.map((opt) => this.getOptionId(opt)).join(' ');
    return optionIds;
  }

  /**
   * Method that gets executed when the fake input used to handle autoFill
   * changes value
   */
  onAutoFill(event: any) {
    if (this.formControl && event.target && event.target.value) {
      this.formControl.setValue(event.target.value);
    }
  }
}
