export enum QrCodeStatus {
  DISPLAY = 'Display',
  NO_DISPLAY = 'Do Not Display',
}

export abstract class QrCodeStatuses {
  public static all: string[] = [
    QrCodeStatus.DISPLAY,
    QrCodeStatus.NO_DISPLAY,
  ];
}
