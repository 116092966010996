<mat-form-field appearance="outline" class="form-array w-100" floatLabel="always">
  <mat-label>{{label}}</mat-label>

  <!-- Hidden matInput plugs into formControl -->
  <template [ngTemplateOutlet]="inputTemplate"></template>
  <input class="d-none" matInput [errorStateMatcher]="matcher" [formControl]="formControl">

  <ng-container [ngTemplateOutlet]="optionsList" />

  <mat-error *ngIf="formControl && formControl.invalid" class="error-message" [innerHTML]="errors" />

  <mat-hint>
    {{hint}}
  </mat-hint>

</mat-form-field>

<ng-template #optionsList>
  <mat-list class="height-auto h-100 p-0 fx-column">

    <!-- List of all uploaded files-->
    <ng-container *ngFor="let childForm of form?.forms">

      <mat-list-item #listItem="hovered" class="border-bottom py-1 font-size-inherit" lcHovered>

        <div class="w-100 fx-row y-center fx-gap-sm" [style.min-height.px]="40">

          <!-- Display file icon and file information-->
          <template [ngTemplateOutlet]="childForm.isEdit && editTemplate ? editMode : displayMode"  [ngTemplateOutletContext]="{form: childForm}"></template>

          <ng-template #displayMode>
            <template [ngTemplateOutlet]="viewTemplate" [ngTemplateOutletContext]="{form: childForm}"></template>

            <div class="fx-1-1-0">
              <mat-icon *ngIf="childForm?.dirty" color="primary"
                        matTooltip="Unsaved changes">published_with_changes</mat-icon>
            </div>

            <!-- Delete Button: displays when hovered -->
            <div class="delete-button fx-row x-end y-center fx-0-0">
              <ng-container *ngIf="listItem.isHovered">
                <button mat-icon-button>
                  <mat-icon *ngIf="canEdit && childForm.canEdit !== false" class="material-icons-outlined" (click)="onEdit(childForm)">
                    edit
                  </mat-icon>
                </button>

                <button mat-icon-button>
                  <mat-icon class="material-icons-outlined" (click)="onDelete(childForm)">
                    delete</mat-icon>
                </button>
              </ng-container>
            </div>

          </ng-template>

          <ng-template #editMode>
            <form *ngIf="childForm && editTemplate" class="w-100" [formGroup]="childForm">
              <template [ngTemplateOutlet]="editTemplate" [ngTemplateOutletContext]="{form: childForm}"></template>

              <div class="fx-row x-space-between fx-gap-xs">
                <button mat-stroked-button (click)="onCancel(childForm)">Cancel</button>
                <button color="primary" mat-raised-button (click)="onDone(childForm)">Save</button>
              </div>
            </form>
          </ng-template>

        </div>
      </mat-list-item>

    </ng-container>

    <mat-list-item *ngIf="canAdd" class="p-0">
      <button mat-icon-button (click)="form.addControl()">
        <mat-icon>add</mat-icon>
      </button>
    </mat-list-item>
  </mat-list>

</ng-template>
