// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

// Internal Component
import { RadioGroupComponent } from './radio-group.component';

const angularModules = [
  CommonModule, ReactiveFormsModule,
  MatRadioModule, MatInputModule, MatTooltipModule,
  MatIcon,
];

@NgModule({
  declarations: [RadioGroupComponent],
  exports: [RadioGroupComponent],
  imports: [...angularModules],
})
export class RadioGroupModule {}
