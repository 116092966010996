<div class="fx-column fx-gap-sm">
  <div class="img-container crop-container" [class.round-cropper]="settings?.isRound">
    <img *ngIf="photo" crossOrigin="anonymous" id="image" [dimensions]="dimensions" [lcPhotoCropper]="photo" [settings]="settings" (loaded)="onLoaded()" (zoomChange)="zoom = $event">
  </div>

  <div *ngIf="settings?.customAspectRatios?.length" class="fx-row x-center">
    <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="settings.aspectRatio" (change)="updateAspectRatio()">
      <mat-button-toggle *ngFor="let aspect of settings.customAspectRatios | keyvalue" [aria-label]="aspect + ' ratio'" [value]="aspect.value">
        {{aspectRatios[aspect.value]}}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div *ngIf="isLoaded" class="fx-row fx-gap-sm y-center">
    <button mat-icon-button (click)="onZoomOut()"><mat-icon>photo_size_select_large</mat-icon></button>
    <mat-slider class="fx-1-1-0" color="primary" step=".01" [discrete]="false" [max]="maxZoom" [min]="0.1">

      <input #slider matSliderThumb [(ngModel)]="zoom"  (change)="onZoomChanged()">
    </mat-slider>
    <button mat-icon-button (click)="onZoomIn()"><mat-icon>photo_size_select_actual</mat-icon></button>
  </div>
</div>
