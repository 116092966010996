<!-- Date Range Input -->
<mat-form-field *ngIf="enableRangeSelection" appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <mat-date-range-input [max]="maxDate" [min]="minDate" [rangePicker]="picker" >
    <input matStartDate [formControl]="startDateControl" [readonly]="readonly">
    <input matEndDate [formControl]="endDateControl" [readonly]="readonly">
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker" />
  <mat-date-range-picker #picker />
</mat-form-field>

<!-- Single Date Input -->
<mat-form-field *ngIf="!enableRangeSelection" appearance="outline">
  <mat-label [id]="idPrefix + '-label'" >{{label}}</mat-label>
  <input matInput [errorStateMatcher]="matcher" [formControl]="formControl" [id]="idPrefix + '-input'" [matDatepicker]="picker"  [max]="maxDate"
         [min]="minDate"
         [placeholder]="placeholder"
         [readonly]="readonly">
  <mat-datepicker-toggle matPrefix [disabled]="readonly || disabled" [for]="picker" />
  <mat-datepicker #picker />
  <mat-hint [id]="idPrefix + '-hint'">{{hint}}</mat-hint>
  <mat-error *ngIf="formControl && formControl.invalid" [id]="idPrefix + '-error'" [innerHTML]="errors" />
</mat-form-field>
